import React, {
  useState,
  Fragment,
  useContext,
  useCallback,
  ChangeEvent,
  useEffect
} from 'react'

import { useNavigate } from 'react-router-dom'

import {
  ButtonComponent,
  CustomerInfoLabel,
  ReasonOptionComponent,
  SelectQuantityComponent,
  SelectComponent,
  ModalComponent,
  TermOfSupplyComponent
} from '../../components'

import { Container } from '../../layout'
import { selectedCustomerContext } from '../../contexts/SelectedCustomer/selectedCustomer'
import { tokenAccessContext } from '../../contexts/TokenAccess/tokenAccessContext'
import { selectedGenderSizeContext } from '../../contexts/SelectedGenderSize/selectedGenderSize'
import { useCartContext } from '../../contexts/CartProvider/useCartContext'
import {
  finalPricesSave,
  finalizePurchase,
  getMethodsPayment
} from '../../services'
import {
  standByPurchaseGet,
  standByPurchaseRemove,
  standByPurchaseSave
} from '../../services/standby.service'
import { showErrorToast, showSuccessToast } from '../../utils'
import {
  ICheckoutRequest,
  IFinalPrices,
  IPaymentMethod,
  ISelectOption,
  IShopperBuyProducts,
  IStandByPurchase,
  IStandByPurchaseSave
} from '../../interfaces'
import { routesNameEnum } from '../../routes/routesNameEnum'
import { motivesMock } from './mock'
import { useLoading } from '../../hooks'

import * as S from './styles'

const OrderQuestionnaireTemplate: React.FC = () => {
  const navigate = useNavigate()

  const { customer, resetSelectedCustomer } = useContext(
    selectedCustomerContext
  )
  const { genderSize, resetGenderSize } = useContext(selectedGenderSizeContext)
  const { token } = useContext(tokenAccessContext)
  const { cartItems, actions: CartContextActions } = useCartContext()
  const { isLoading, startLoading, stopLoading } = useLoading()
  const [selectedReasonsId, setSelectedReasonsId] = useState<string[]>([])
  const [isWhatsappBoxChecked, setIsWhatsappBoxChecked] =
    useState<boolean>(false)
  const [quantityOfProducts, setQuantityOfProducts] = useState(cartItems.length)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [finalizingPurchase, setFinalizingPurchase] = useState(false) //btn finalizar click status
  const [openTermsModal, setOpenTermsModal] = useState<boolean>(false)
  const [paymentMethodOptionSelected, setPaymentMethodOptionSelected] =
    useState<ISelectOption>({
      label: '',
      value: ''
    })
  const [standbyPurchasesList, setStandbyPurchasesList] = useState<
    IStandByPurchase[]
  >([])
  const [paymentMethods, setPaymentMethods] = useState<ISelectOption[]>([
    {
      label: '',
      value: ''
    }
  ])
  const [methodsList, setMethodsList] = useState<IPaymentMethod[]>([])

  const [partnerSelected, setPartnerSelected] = useState<ISelectOption>({
    label: '',
    value: ''
  })
  const [partnerList, setPartnerList] = useState<ISelectOption[]>([
    {
      label: '',
      value: ''
    }
  ])
  const [finalPriceTotal, setFinalPriceTotal] = useState<number>(0)
  const [finalPriceProducts, setFinalPriceProducts] = useState<
    IShopperBuyProducts[]
  >([])

  const standById = localStorage.getItem('standById')
  const shopperId = Number(localStorage.getItem('shopperId'))

  const handleChangeSelectedReason = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      if (event.target.checked) {
        setSelectedReasonsId((prevState) => [...prevState, value])
        return
      }
      setSelectedReasonsId((prevState) =>
        prevState.filter((reason) => reason !== value)
      )
    },
    []
  )

  const handleIncrementQuantityOfDaniedParts = useCallback(() => {
    setQuantityOfProducts((prevState) => {
      return prevState + 1
    })
  }, [])

  const handleDecrementQuantityOfDaniedParts = useCallback(() => {
    setQuantityOfProducts((prevState) => {
      if (prevState > 0) {
        return prevState - 1
      } else {
        return (prevState = 0)
      }
    })
  }, [])

  const handleSelectedPaymentMethodOption = useCallback(
    (value: ISelectOption) => {
      const selectedMethod = Number(value.value)
      if (selectedMethod !== 8) {
        setPartnerSelected({
          label: '',
          value: ''
        })
      }
      setPaymentMethodOptionSelected(value)
    },
    []
  )

  const handleSelectedPartner = useCallback((value: ISelectOption) => {
    setPartnerSelected(value)
  }, [])

  const handleChangeProductsQuantity = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      const valueAsNumber = Number(value)

      if (isNaN(valueAsNumber)) {
        const newValue = value.slice(0, -1)
        setQuantityOfProducts(Number(newValue))
      } else {
        setQuantityOfProducts(Number(value))
      }
    },
    []
  )

  const removeStandbyPurchaseItem = async () => {
    try {
      const standbyPurchase = standbyPurchasesList.find(
        (item) => standById && item.id === parseInt(standById)
      )

      if (standbyPurchase && standById) {
        const response = await standByPurchaseRemove(
          token.accessToken,
          standById,
          shopperId
        )
        console.log(response)
        const updatedStandbyPurchasesList = standbyPurchasesList.filter(
          (item) => item.id !== parseInt(standById)
        )
        setStandbyPurchasesList(updatedStandbyPurchasesList)
      }
    } catch (error) {
      console.error(`Error removing standby purchase: ${error}`)
    }
  }

  const savePurchaseInStandbyHandler = async () => {
    const requestBody: IStandByPurchaseSave = {
      shopper: shopperId,
      genderSize: genderSize,
      customer: {
        id: customer && customer.id,
        name: customer && customer.name,
        email: customer?.email,
        document: customer?.document
      },
      items: cartItems
    }
    try {
      const response = await standByPurchaseSave(token.accessToken, requestBody)

      if (response) {
        removeStandbyPurchaseItem()
        CartContextActions?.clearCartAction?.()
        resetGenderSize?.()
        resetSelectedCustomer?.()
        showSuccessToast('Compra salva em standby com sucesso!')
        navigate(routesNameEnum.HOME)
      } else {
        return { success: false }
      }
    } catch (error) {
      console.error(`Erro ao salvar em standby: ${error}`)
      return { success: false }
    }
  }

  useEffect(() => {
    async function getStandbyPurchases() {
      try {
        const response = await standByPurchaseGet(token.accessToken, shopperId)
        setStandbyPurchasesList(response.data)
      } catch (error) {
        console.error(`Error getting standby purchases: ${error}`)
      }
    }
    const methodsPayment = async () => {
      try {
        const response = await getMethodsPayment(token.accessToken)
        setMethodsList(response.data)
        const newList = response.data.map((method: IPaymentMethod) => ({
          label: method.name,
          value: method.id.toString()
        }))
        setPaymentMethods(newList)
      } catch (error) {
        console.error(`Error getting standby purchases: ${error}`)
      }
    }
    getStandbyPurchases()
    methodsPayment()
  }, [token.accessToken, shopperId])

  const calculateFinalPrice = async () => {
    const requestBody: IFinalPrices = {
      paymentId: Number(paymentMethodOptionSelected.value),
      partnerId:
        partnerSelected.value === '' ? null : Number(partnerSelected.value),
      products: [
        ...cartItems.map((item) => {
          return {
            categoryId: item.categoryId,
            brandId: item.brandId,
            sizeId: Number(item.size),
            genderId: Number(item.gender),
            priceSell: parseFloat(item.dazPrice),
            description: item.description,
            active: item.itemEnabledForPurchase
          }
        })
      ]
    }

    if (requestBody.paymentId !== 0) {
      try {
        startLoading()
        const response = await finalPricesSave(token.accessToken, requestBody)
        if (response) {
          setFinalPriceTotal(response.data.total)
          setFinalPriceProducts(response.data.products)
        }
      } catch (error) {
        console.error(`Error getting final price: ${error}`)
      } finally {
        stopLoading()
      }
    }
  }

  useEffect(() => {
    calculateFinalPrice()
    // eslint-disable-next-line
  }, [paymentMethodOptionSelected, partnerSelected])

  useEffect(() => {
    const selectedMethod = Number(paymentMethodOptionSelected.value)
    methodsList.find((method) => {
      if (selectedMethod === method.id) {
        if (method.partners.length > 0) {
          const newList = method.partners.map((partner: IPaymentMethod) => ({
            label: partner.name,
            value: partner.id.toString()
          }))
          setPartnerList(newList)
        }
      }
    })
    if (paymentMethodOptionSelected.value !== '8') {
      setPartnerSelected({
        label: '',
        value: ''
      })
    }
  }, [paymentMethodOptionSelected, methodsList])

  const refusePurchaseHandler = async () => {
    if (customer) {
      const requestBody: ICheckoutRequest = {
        shopperId: Number(localStorage.getItem('shopperId')),
        sellerId: customer.id ? parseInt(customer.id) : 0,
        priceTotal: parseFloat(finalPriceTotal.toFixed(2)),
        consignTotal:
          paymentMethodOptionSelected.value === '2'
            ? parseFloat(finalPriceTotal.toFixed(2))
            : 0,
        cashTotal:
          paymentMethodOptionSelected.value === '6'
            ? parseFloat(finalPriceTotal.toFixed(2))
            : 0,
        creditTotal:
          paymentMethodOptionSelected.value === '5'
            ? parseFloat(finalPriceTotal.toFixed(2))
            : 0,
        circularValleyTotal:
          paymentMethodOptionSelected.value === '8'
            ? parseFloat(finalPriceTotal.toFixed(2))
            : 0,
        partnerId:
          partnerSelected.value === '' ? null : Number(partnerSelected.value),
        validatedProducts: quantityOfProducts,
        obs: null,
        reasonsNotBuy: selectedReasonsId,
        whatsapp: isWhatsappBoxChecked,
        shopperBuyProducts: [
          ...finalPriceProducts.map((item) => {
            return {
              brandId: item.brandId,
              sizeId: item.sizeId,
              genderId: item.genderId,
              colorId: 56,
              categoryId: item.categoryId,
              paymentId: Number(paymentMethodOptionSelected.value),
              commission: item.comission ?? 0,
              offSeasonPercentage: item.offSeasonPercentage ?? null,
              partnerId:
                partnerSelected.value === ''
                  ? null
                  : Number(partnerSelected.value),
              priceOriginal: item.priceOriginal ?? null,
              priceBuy: item.priceBuy ?? 0,
              priceSell: item.priceSell,
              active: item.active === true && false
            }
          })
        ]
      }

      try {
        const response = await finalizePurchase(token.accessToken, requestBody)

        if (response) {
          removeStandbyPurchaseItem()
          CartContextActions?.clearCartAction?.()
          resetSelectedCustomer?.()
          navigate(routesNameEnum.HOME)
          showSuccessToast('Compra não aceita!')
          return { success: true }
        } else {
          return { success: false }
        }
      } catch (error) {
        console.error(`Erro ao não aceitar compra: ${error}`)
        return { success: false }
      }
    }
  }

  const checkoutHandler = async () => {
    if (finalizingPurchase) {
      return
    }

    if (customer) {
      const requestBody: ICheckoutRequest = {
        shopperId: Number(localStorage.getItem('shopperId')),
        sellerId: customer.id ? parseInt(customer.id) : 0,
        priceTotal: parseFloat(finalPriceTotal.toFixed(2)),
        consignTotal:
          paymentMethodOptionSelected.value === '2'
            ? parseFloat(finalPriceTotal.toFixed(2))
            : 0,
        cashTotal:
          paymentMethodOptionSelected.value === '6'
            ? parseFloat(finalPriceTotal.toFixed(2))
            : 0,
        creditTotal:
          paymentMethodOptionSelected.value === '5'
            ? parseFloat(finalPriceTotal.toFixed(2))
            : 0,
        circularValleyTotal:
          paymentMethodOptionSelected.value === '8'
            ? parseFloat(finalPriceTotal.toFixed(2))
            : 0,
        partnerId:
          partnerSelected.value === '' ? null : Number(partnerSelected.value),
        validatedProducts: quantityOfProducts,
        obs: null,
        reasonsNotBuy: selectedReasonsId,
        whatsapp: isWhatsappBoxChecked,
        shopperBuyProducts: [
          ...finalPriceProducts.map((item) => {
            return {
              brandId: item.brandId,
              sizeId: item.sizeId,
              genderId: item.genderId,
              colorId: 56,
              categoryId: item.categoryId,
              paymentId: Number(paymentMethodOptionSelected.value),
              commission: item.comission ?? 0,
              offSeasonPercentage: item.offSeasonPercentage ?? null,
              partnerId:
                partnerSelected.value === ''
                  ? null
                  : Number(partnerSelected.value),
              priceOriginal: item.priceOriginal ?? null,
              priceBuy: item.priceBuy ?? 0,
              priceSell: item.priceSell,
              active: item.active
            }
          })
        ]
      }
      try {
        setFinalizingPurchase(true)
        const response = await finalizePurchase(token.accessToken, requestBody)
        if (response === undefined || response.code == 500) {
          console.error(`Erro ao finalizar a compra`)
          showErrorToast(
            'Algo deu errado, notificamos nosso suporte para verificar sua compra'
          )
          return { success: false }
        } else {
          console.log(response)
          // TODO: change to the correct one coming from the api
          const purchaseLinkGeneratedForSharing = `https://shopper.dazroupaz.com.br/cid/${response.order_id}`
          const requestCode = response.order_id
          removeStandbyPurchaseItem()
          CartContextActions?.clearCartAction?.()
          navigate(routesNameEnum.SHARE_PURCHASE, {
            state: {
              // TODO: switch to the correct information coming from the api
              linkForSharing: purchaseLinkGeneratedForSharing,
              orderCode: requestCode
            }
          })
          return { success: true }
        }
      } catch (error) {
        console.error(`Erro ao finalizar a compra: ${error}`)
        showErrorToast(
          'Algo deu errado, notificamos nosso suporte para verificar sua compra'
        )
        return { success: false }
      } finally {
        setFinalizingPurchase(false)
      }
    }
  }

  return (
    <Fragment>
      <CustomerInfoLabel
        label={`Cliente: ${customer?.email ? customer?.email : customer?.name}`}
      />
      <Container>
        <S.OrderQuestionnaireTemplateWrapper>
          <S.PaymentMethodSelectWrapper>
            <S.ScreenTitle>Modo de pagamento</S.ScreenTitle>
            <S.SelectContainer>
              <S.SelectWrapperTop>
                <SelectComponent
                  options={paymentMethods}
                  selectedOption={paymentMethodOptionSelected}
                  handleSelectedOption={handleSelectedPaymentMethodOption}
                />
              </S.SelectWrapperTop>
              {paymentMethodOptionSelected.value === '8' && (
                <S.SelectWrapperBottom>
                  <S.GenericTitle>Selecione o vale</S.GenericTitle>
                  <SelectComponent
                    options={partnerList}
                    selectedOption={partnerSelected}
                    handleSelectedOption={handleSelectedPartner}
                  />
                </S.SelectWrapperBottom>
              )}
            </S.SelectContainer>
            <S.PriceContainer style={{ marginTop: '15px' }}>
              Valor Total:
              {isLoading ? (
                <S.SpinnerContainer />
              ) : (
                ` R$ ${finalPriceTotal.toFixed(2).replace('.', ',')}`
              )}
            </S.PriceContainer>
            {paymentMethodOptionSelected.value && (
              <S.ModalDetails onClick={() => setOpenModal(true)}>
                + Detalhes
              </S.ModalDetails>
            )}
          </S.PaymentMethodSelectWrapper>
          <S.WithdrawalQuestionnaireWrapper>
            <div style={{ marginBottom: '15px', textAlign: 'center' }}>
              <S.ScreenTitle>Questionário da retirada</S.ScreenTitle>
            </div>
            <S.QuantitySelectedWrapper>
              <S.GenericTitle>Quantas peças foram avaliadas?</S.GenericTitle>
              <SelectQuantityComponent
                quantity={quantityOfProducts}
                onChange={handleChangeProductsQuantity}
                decreaseFn={handleDecrementQuantityOfDaniedParts}
                increaseFn={handleIncrementQuantityOfDaniedParts}
              />
            </S.QuantitySelectedWrapper>
            <S.ReasonsWrapper>
              <div style={{ marginBottom: '15px', textAlign: 'center' }}>
                <S.GenericTitle>
                  Motivos pelos quais algumas peças não foram aceitas:
                </S.GenericTitle>
              </div>
              <S.ReasonOptionsWrapper>
                {/* TODO: switch to the correct information coming from the api */}
                {motivesMock.map((reason) => (
                  <S.ReasonOptionItem key={reason.id}>
                    <ReasonOptionComponent
                      id={reason.id}
                      label={reason.label}
                      value={reason.id}
                      selectedReasonsId={selectedReasonsId}
                      handleChange={handleChangeSelectedReason}
                    />
                  </S.ReasonOptionItem>
                ))}
              </S.ReasonOptionsWrapper>
            </S.ReasonsWrapper>
          </S.WithdrawalQuestionnaireWrapper>
          <S.ButtonsActionsWrapper>
            <ButtonComponent onClick={savePurchaseInStandbyHandler}>
              SALVAR EM STANDBY
            </ButtonComponent>
            <ButtonComponent
              disabled={!paymentMethodOptionSelected.value}
              onClick={refusePurchaseHandler}
            >
              NÃO ACEITO
            </ButtonComponent>
            <ButtonComponent
              disabled={!paymentMethodOptionSelected.value}
              onClick={setOpenTermsModal}
            >
              FINALIZAR
            </ButtonComponent>
          </S.ButtonsActionsWrapper>
        </S.OrderQuestionnaireTemplateWrapper>
        <ModalComponent
          withBackground
          isActive={openModal}
          onClose={() => setOpenModal(false)}
          titleModal="Carrinho"
        >
          {finalPriceProducts
            .filter((item) => item.active)
            .map((item, index) => (
              <S.ItemsContainer key={index}>
                <div>
                  <span></span>
                  <p>{item.description}</p>
                </div>
                <p>{`R$ ${
                  item.priceBuy && item.priceBuy.toFixed(2).replace('.', ',')
                }`}</p>
              </S.ItemsContainer>
            ))}
        </ModalComponent>
        <TermOfSupplyComponent
          openModal={openTermsModal}
          setOpenModal={setOpenTermsModal}
          finalPriceTotal={finalPriceTotal}
          checkoutHandler={checkoutHandler}
          isWhatsappBoxChecked={isWhatsappBoxChecked}
          setIsWhatsappBoxChecked={setIsWhatsappBoxChecked}
          finalizingPurchase={finalizingPurchase}
        />
      </Container>
    </Fragment>
  )
}

export default OrderQuestionnaireTemplate
