import { ChangeEvent, useCallback, useContext, useState } from 'react'
import { tokenAccessContext } from '../contexts/TokenAccess/tokenAccessContext'
import { searchCustomer } from '../services'
import { DOCUMENTMask } from '../utils'

export const validationTypes = {
  email: {
    regex:
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    message: 'Digite um email válido'
  },
  number: {
    regex: /^\d+$/,
    message: 'Utilize apenas números'
  },
  text: {
    regex: /[a-zA-Z\u00C0-\u00FF ]+/i,
    message: 'Utilize apenas letras'
  },
  phone: {
    regex: /(([1-9]{2})|([0-9]{3})?)(\d{4,5})([-])(\d{4})/,
    message: 'Digite um número de telefone válido'
  },
  document: {
    regex: /^([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2})$|^([A-Z]{2})([0-9]{6})$/,
    message: 'Digite um documento válido'
  },
  CNPJ: {
    regex: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
    message: 'Digite um CNPJ válido'
  },
  PIX: {
    regex: /(\w)|(\d)|(\W)/,
    message: 'Digite uma chave PIX válida'
  }
}

type InputTypes =
  | 'email'
  | 'number'
  | 'text'
  | 'phone'
  | 'document'
  | 'CNPJ'
  | 'PIX'

const useForm = (inputType: InputTypes, required: boolean = true) => {
  const [value, setValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { token } = useContext(tokenAccessContext)

  const docVerification = useCallback(async () => {
    const doc = value.replace(/[.\-+()\s]+/g, '')

    const response = await searchCustomer(
      token.accessToken,
      doc.toLocaleLowerCase(),
      0
    )

    if (response?.code === 200) {
      setErrorMessage('Documento já adicionado')
      return false
    }

    return true
  }, [token, value])

  const validate = (value: string) => {
    if (!inputType || !required) return true

    if (!value.length) {
      setErrorMessage('Por favor digite um valor')
      return false
    }

    if (
      inputType === 'document' &&
      !validationTypes[inputType].regex.test(DOCUMENTMask(value))
    ) {
      setErrorMessage(validationTypes[inputType].message)
      return false
    }

    if (
      inputType === 'document' &&
      validationTypes[inputType] &&
      !!validationTypes[inputType].regex.test(value)
    ) {
      docVerification()
    }

    if (
      inputType !== 'document' &&
      validationTypes[inputType] &&
      !validationTypes[inputType].regex.test(value)
    ) {
      setErrorMessage(validationTypes[inputType].message)
      return false
    }

    setErrorMessage('')
    return true
  }

  const onChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (errorMessage) validate(target.value)
      setValue(target.value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorMessage]
  )

  return {
    value,
    setValue,
    onChange,
    errorMessage,
    validate: () => validate(value),
    onBlur: () => validate(value)
  }
}

export { useForm }
