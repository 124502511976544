import React, { ChangeEvent, useCallback, useContext } from 'react'

import { ButtonComponent, ModalComponent } from '../../components'
import { selectedCustomerContext } from '../../contexts/SelectedCustomer/selectedCustomer'
import { TermOfSupplyProps } from './types'
import { ICartItem } from '../../interfaces'
import { CPFMask } from '../../utils'

import * as S from './styles'

const TermOfSupplyComponent: React.FC<TermOfSupplyProps> = ({
  openModal,
  setOpenModal,
  finalPriceTotal,
  checkoutHandler,
  isWhatsappBoxChecked,
  setIsWhatsappBoxChecked,
  finalizingPurchase
}) => {
  const { customer } = useContext(selectedCustomerContext)
  const currentDate = new Date()
  const formattedDate = currentDate.toLocaleDateString('pt-BR')

  const cartItemsString = localStorage.getItem('cartItems')
  const cartItems: ICartItem[] = cartItemsString
    ? JSON.parse(cartItemsString)
    : []

  const handleChangeSelectBox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked === true) {
        setIsWhatsappBoxChecked(true)
      } else {
        setIsWhatsappBoxChecked(false)
      }
    },
    [setIsWhatsappBoxChecked]
  )

  const handleAcceptSupplyTerms = () => {
    checkoutHandler()
  }

  return (
    <ModalComponent
      withBackground
      isActive={openModal}
      onClose={() => setOpenModal(false)}
      titleModal="Termo de fornecimento"
      fillAllHeight
    >
      <S.TermsWrapper>
        <S.ClientInfos>
          <S.ClientInfo>{`Cliente: ${customer?.name}`}</S.ClientInfo>
          {/* eslint-disable-next-line */}
          <S.ClientInfo>{`CPF: ${CPFMask(customer!.document!)}`}</S.ClientInfo>
          {customer?.email && (
            <S.ClientInfo>{`E-mail: ${customer?.email}`}</S.ClientInfo>
          )}
        </S.ClientInfos>
        <S.TermWrapper>
          <S.TermDescription>
            EU, ACIMA IDENTIFICADO, DECLARO PARA TODOS OS EFEITOS E DIREITOS QUE
            AS MERCADORIAS FORNECIDAS, REFERENTE A ATUAL VENDA DE ITENS,
            REALIZADA HOJE {formattedDate}, SÃO DE MINHA PROPRIEDADE E QUE OS
            ITENS SÃO DE MARCAS ORIGINAIS, NÃO SE TRATANDO DE MERCADORIA
            &apos;FAKE&apos; &#40;CÓPIAS, REPLICAS, FALSAS, ETC&#41;. CASO SEJA
            CONSTADO, POSTERIORMENTE, TRATA-SE DE MERCADORIA
            &apos;NÃO-ORIGINAL&apos;, ME COMPROMETO A RECEBE-LA DE VOLTA E
            REEMBOLSAR O VALOR CONSTANTE NA NOTA FISCAL DE COMPRA,
            IMEDIATAMENTE. AUMENTO DE PREÇO PÓS COMPRA PARA COBRIR OS IMPOSTOS.
            <br />
            <br /> &#40;OS DADOS AQUI COLETADOS &#40;NOME, CPF, ENDEREÇO E
            NÚMERO DE TELEFONE&#41; SERÃO UTILIZADOS PARA PROPÓSITOS LEGÍTIMOS,
            ESPECÍFICOS E INFORMADO AO TITULAR DE FORMA CLARA, OBJETIVA E
            TRANSPARENTE DE ACORDO COM A NOSSA POLÍTICA DE PRIVACIDADE. QUANDO
            NECESSÁRIO, A DAZ ROUPAZ PODERÁ COMPARTILHAR SEUS DADOS PESSOAIS COM
            PARCEIROS COMERCIAIS PARA QUE POSSAM OFERECER NOVOS PRODUTOS E/OU
            VIABILIZAR A PRESTAÇÃO DOS SERVIÇOS. O PRESENTE CONSENTIMENTO
            PERMANECE VIGENTE, ENQUANTO MANTIVER RELAÇÃO DE CONSUMO COM A DAZ
            ROUPAZ, E PODERÁ SER REVOGADO A QUALQUER TEMPO, MEDIANTE COMUNICAÇÃO
            ATRAVÉS DO E-MAIL CONTATO@DAZROUPAZ.COM.BR, DISPONIBILIZADO
            ELETRONICAMENTE PELA DAZ ROUPAZ, QUE PASSARÁ A VALER A PARTIR DA
            DATA DE ALTERAÇÃO, CESSANDO QUALQUER TRATAMENTO ADICIONAL AOS DADOS
            FORNECIDOS, RATIFICADOS OS TRATAMENTOS REALIZADOS SOB AMPARO DO
            CONSENTIMENTO ANTERIORMENTE MANIFESTADO ENQUANTO NÃO HOUVER
            REQUERIMENTO DE ELIMINAÇÃO&#41;.
          </S.TermDescription>
        </S.TermWrapper>
        <S.ItemInfo>itens fornecidos &#40;{cartItems.length}&#41;</S.ItemInfo>
        <S.ItemInfo>{`Valor acordado a receber: R$ ${finalPriceTotal
          .toFixed(2)
          .replace('.', ',')}`}</S.ItemInfo>
        <S.WhatsappBoxWrapper htmlFor={'1'}>
          <S.BoxInput isBoxChecked={isWhatsappBoxChecked}>
            <input
              id="1"
              value="1"
              type="checkbox"
              onChange={handleChangeSelectBox}
              checked={isWhatsappBoxChecked}
            />
          </S.BoxInput>
          <S.BoxText>Aceito receber mensagens via Whatsapp</S.BoxText>
        </S.WhatsappBoxWrapper>
        <S.ContentButton>
          <ButtonComponent
            disabled={finalizingPurchase}
            onClick={handleAcceptSupplyTerms}
          >
            Aceito
          </ButtonComponent>
        </S.ContentButton>
      </S.TermsWrapper>
    </ModalComponent>
  )
}

export default TermOfSupplyComponent
